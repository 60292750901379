import { useAffiliateManagerStore } from '../store'

export function updateParent(name: string, value?: any) {
  try {
    const store = useAffiliateManagerStore()
    if (store.appHandshake) {
      store.appHandshake.then((parent: any) => {
        parent.emit(name, value)
      })
    }
  } catch (error) {
    console.error(error)
  }
}
