import '@/assets/index.css'
import '@gohighlevel/ghl-ui/dist/style.css'
import '@iconify/iconify'
import vClickOutside from 'click-outside-vue3'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18'
import router from './route'
import { setupSentry } from './util/setupSentry'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(vClickOutside)
app.mount('#app')
setupSentry(app, router, process.env.NODE_ENV as string)
