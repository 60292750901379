import { createRouter, createWebHistory } from 'vue-router'

import { useAffiliateManagerMediaStore } from '@/store/MediaAssetsStore'
import { useAffiliateManagerStore } from './../store/index'

const routes = [
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager',
    name: 'Home-Old',
    component: () => import('../pages/Home.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/campaigns',
    name: 'Home',
    component: () => import('../pages/Home.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/campaigns/create',
    name: 'CampaignCreate',
    component: () => import('../pages/campaign/Create.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/campaigns/:id',
    name: 'CampaignEdit',
    component: () => import('../pages/campaign/Edit.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/campaigns/:id/integration',
    name: 'CampaignIntegration',
    component: () => import('../pages/campaign/Integration.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/affiliates',
    name: 'affiliate',
    component: () => import('../pages/affiliates/index.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/affiliates/:id',
    name: 'affiliate-details',
    component: () => import('../pages/affiliates/detailsV2.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/payouts',
    name: 'payouts',
    component: () => import('../pages/payouts/index.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/dashboard',
    name: 'dashboard',
    component: () => import('../pages/dashboard/index.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/media',
    name: 'media',
    component: () => import('../pages/media-assets/index.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/media/folder/:folderId',
    name: 'NestedMedia',
    component: () => import('../pages/media-assets/index.vue'),
  },

  {
    path: '/v2/location/:locationId/marketing/affiliate-manager/settings',
    name: 'settings',
    component: () => import('../pages/settings/index.vue'),
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach(to => {
  const store = useAffiliateManagerStore()
  const { emptyDirectory } = useAffiliateManagerMediaStore()
  try {
    if (to?.name === 'media') {
      emptyDirectory()
    }
    if (
      to?.name === 'CampaignCreate' ||
      to?.name === 'CampaignEdit' ||
      to?.name == 'CampaignIntegration'
    ) {
      store.updateFullScreen(true)
    } else {
      if (store.showFullScreen) store.updateFullScreen(false)
    }
    if (store.changeRoute) {
      store.setChangeRouteFlag(false)
    } else if (store.appHandshake) {
      store.appHandshake.then(parent => {
        parent.emit('spm-ts', {
          router: to.fullPath,
        })
      })
    }
  } catch (error) {
    console.log(error)
  }
})

export default router
