import { createI18n } from 'vue-i18n'

/**
 * Multi locales support generator  for i18n, to add new language put the language code in the supportedLocales = ['en','xxx']
 * and add the json as such './locales/xxx.json'
 */
export const supportedLocales = [
  'en-US',
  'es',
  'fr-CA',
  'fr-FR',
  'nl',
  'de',
  'pr-PT',
  'pr-BR',
  'it',
  'sv',
  'da',
  'fi',
  'no',
]

function loadLocaleMessages() {
  const messages: any = {}

  let modules = import.meta.globEager('./locales/*.json')
  supportedLocales.forEach(locale => {
    messages[locale] = modules[`./locales/${locale}.json`]
  })
  return messages
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: loadLocaleMessages(),
})
